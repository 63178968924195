import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 500.000000 500.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)">
  


<path d="M0 2500 l0 -2500 2500 0 2500 0 0 2500 0 2500 -2500 0 -2500 0 0
-2500z m1520 682 c0 -9 -84 -52 -101 -52 -6 0 -9 6 -6 14 5 13 1 13 -28 0 -41
-17 -58 -18 -30 -2 20 12 130 46 153 47 6 1 12 -3 12 -7z m80 -7 c0 -18 -56
-49 -76 -41 -22 8 -17 24 14 40 35 20 62 20 62 1z m88 3 c19 -19 14 -37 -13
-44 -34 -8 -58 12 -44 37 11 22 39 25 57 7z m105 -16 c24 -21 21 -32 -8 -32
-26 0 -68 37 -59 52 7 11 44 0 67 -20z m141 -15 c27 -12 44 -23 37 -25 -6 -2
-23 3 -36 12 -30 20 -36 20 -29 1 4 -8 1 -15 -7 -15 -13 0 -99 48 -99 56 0 9
87 -9 134 -29z m-469 -48 c13 -20 -6 -60 -38 -79 -87 -54 -116 15 -30 71 31
21 59 24 68 8z m153 -1 c7 -7 12 -24 12 -39 0 -49 -70 -78 -104 -43 -23 22
-20 41 10 69 29 27 63 32 82 13z m-278 -9 c0 -7 -16 -27 -36 -45 -57 -54 -122
-61 -73 -8 44 49 109 80 109 53z m454 -22 c16 -21 18 -32 10 -45 -27 -43 -114
-15 -114 37 0 36 7 43 49 39 23 -2 40 -12 55 -31z m109 22 c84 -44 90 -123 6
-89 -35 15 -59 46 -59 77 0 26 19 30 53 12z m-683 -40 c-52 -46 -80 -64 -80
-51 0 14 103 91 120 91 3 0 -15 -18 -40 -40z m792 32 c54 -22 113 -101 75
-101 -20 0 -79 47 -100 80 -20 31 -14 36 25 21z m133 -65 c22 -19 37 -37 33
-42 -7 -6 -99 70 -107 89 -5 14 30 -8 74 -47z m-651 -49 c37 -27 11 -110 -38
-123 -75 -18 -120 29 -79 84 35 47 83 64 117 39z m204 -3 c77 -54 17 -158 -71
-125 -38 15 -53 52 -38 89 20 48 69 64 109 36z m-543 -37 c-8 -29 -76 -107
-93 -107 -31 0 0 70 53 123 32 31 50 24 40 -16z m153 10 c3 -22 -6 -37 -36
-67 -42 -42 -71 -50 -92 -25 -16 19 2 66 38 102 35 35 86 29 90 -10z m605 9
c54 -50 44 -116 -19 -116 -40 0 -94 44 -94 77 0 59 67 82 113 39z m168 -9 c65
-56 66 -143 2 -120 -51 17 -99 99 -78 132 11 18 49 12 76 -12z m117 -12 c47
-40 81 -125 49 -125 -28 0 -97 96 -97 135 0 22 14 19 48 -10z m-1151 -48 c-6
-15 -16 -27 -20 -27 -5 0 0 13 9 30 22 36 29 34 11 -3z m1241 -43 c-4 -3 -12
5 -18 18 -12 22 -12 22 6 6 10 -10 15 -20 12 -24z m-1150 -36 c20 -20 14 -82
-11 -119 -17 -26 -30 -34 -52 -34 -30 0 -30 1 -30 54 1 47 5 57 33 82 36 32
43 34 60 17z m216 -10 c20 -28 20 -51 0 -89 -19 -38 -73 -63 -107 -50 -27 11
-37 29 -37 73 0 70 107 119 144 66z m241 2 c48 -53 3 -144 -71 -144 -76 0
-107 83 -52 141 29 31 97 32 123 3z m446 0 c24 -14 49 -61 49 -96 0 -77 -97
-87 -135 -14 -40 78 14 148 86 110z m-1021 -20 c0 -29 -38 -126 -55 -140 -33
-28 -10 103 26 147 16 19 29 16 29 -7z m431 -189 c32 -33 37 -67 15 -110 -15
-28 -53 -51 -85 -51 -57 0 -96 82 -67 138 31 60 90 70 137 23z m258 3 c26 -21
31 -33 31 -67 -1 -45 -13 -67 -47 -85 -79 -43 -161 45 -118 127 28 55 86 66
134 25z m-674 -47 c9 -57 -13 -121 -42 -125 -17 -3 -24 3 -30 25 -21 80 8 174
47 153 12 -6 21 -26 25 -53z m171 42 c28 -31 26 -100 -5 -133 -51 -54 -111
-22 -111 60 0 79 70 124 116 73z m-276 -90 c0 -57 -4 -88 -10 -84 -16 10 -10
175 6 175 2 0 4 -41 4 -91z m1130 -15 c0 -33 -1 -34 -40 -34 l-40 0 0 -120 0
-120 -40 0 -40 0 0 120 0 120 -45 0 c-43 0 -45 1 -45 28 0 16 3 31 6 34 3 4
59 6 125 6 l119 0 0 -34z m180 -24 l0 -60 50 0 49 0 3 57 3 58 35 0 35 0 3
-152 3 -153 -41 0 -40 0 0 60 0 60 -50 0 -50 0 0 -60 0 -60 -35 0 -35 0 0 149
c0 157 0 159 48 160 20 1 22 -3 22 -59z m528 23 l3 -33 -76 0 -76 0 3 -27 c3
-26 7 -28 56 -31 50 -3 52 -4 52 -33 0 -29 -1 -29 -55 -29 -54 0 -55 0 -55
-30 l0 -30 75 0 75 0 0 -30 0 -30 -115 0 -115 0 0 148 c0 82 3 152 7 156 4 3
54 5 112 4 l106 -3 3 -32z m560 0 l3 -33 -79 0 c-66 0 -81 -3 -86 -17 -11 -27
22 -43 89 -43 34 0 66 -5 73 -12 15 -15 16 -122 2 -149 -10 -17 -22 -19 -120
-19 l-110 0 0 30 0 29 78 3 c76 3 77 3 80 31 l3 27 -73 0 c-44 0 -79 5 -86 12
-13 13 -16 135 -4 155 8 13 74 20 167 19 l60 -1 3 -32z m210 -91 l3 -123 42 3
42 3 5 120 5 120 38 3 37 3 0 -143 c0 -99 -4 -148 -12 -156 -16 -16 -210 -16
-226 0 -12 12 -18 278 -7 290 3 3 20 5 38 4 l32 -1 3 -123z m527 108 c18 -15
21 -110 3 -128 -9 -9 -9 -15 0 -24 16 -16 16 -110 0 -126 -8 -8 -50 -12 -125
-12 l-113 0 0 149 0 150 33 3 c72 7 187 0 202 -12z m-2734 -150 c25 -25 30
-36 27 -71 -4 -55 -41 -86 -84 -72 -64 21 -92 116 -45 154 33 27 68 24 102
-11z m250 1 c36 -36 38 -80 7 -115 -55 -60 -148 -20 -148 63 0 77 85 108 141
52z m-461 -11 c41 -58 35 -140 -10 -140 -47 0 -91 90 -71 143 15 38 53 37 81
-3z m-126 -38 c20 -56 21 -115 4 -110 -27 9 -69 132 -53 157 12 20 34 -1 49
-47z m273 -167 c29 -29 34 -40 31 -72 -3 -32 -7 -38 -28 -40 -58 -6 -125 98
-88 135 21 21 49 13 85 -23z m-156 -6 c30 -32 58 -104 45 -116 -18 -19 -92 64
-102 115 -9 43 17 44 57 1z m-56 -53 c37 -56 44 -76 21 -56 -17 13 -76 103
-76 114 0 15 21 -7 55 -58z m1200 14 c4 -6 -8 -10 -29 -10 -32 0 -36 -3 -36
-25 0 -21 5 -25 30 -25 17 0 30 -4 30 -10 0 -5 -13 -10 -30 -10 -25 0 -30 -4
-30 -25 0 -21 5 -25 29 -25 17 0 33 -4 36 -10 4 -6 -12 -10 -42 -10 l-48 0 1
78 c1 42 2 78 3 80 3 7 81 0 86 -8z m229 -21 l19 -31 19 31 c11 17 24 31 29
31 14 0 11 -9 -12 -42 -22 -30 -22 -30 -3 -66 10 -21 20 -40 22 -44 2 -5 -1
-8 -7 -8 -6 0 -18 14 -27 32 l-17 32 -18 -22 c-9 -12 -19 -27 -21 -34 -2 -6
-9 -9 -16 -7 -8 3 -4 16 13 42 l26 38 -20 33 c-23 36 -25 46 -13 46 4 0 16
-14 26 -31z m344 25 c51 -14 30 -94 -25 -94 -19 0 -23 -5 -23 -30 0 -16 -4
-30 -10 -30 -6 0 -10 33 -10 80 0 87 4 91 68 74z m322 -4 c0 -5 -16 -10 -35
-10 -31 0 -35 -3 -35 -25 0 -22 4 -25 35 -25 19 0 35 -4 35 -10 0 -5 -16 -10
-35 -10 -31 0 -35 -3 -35 -25 0 -22 4 -25 35 -25 19 0 35 -4 35 -10 0 -5 -20
-10 -45 -10 l-45 0 0 73 c0 41 3 77 7 80 10 11 83 8 83 -3z m278 4 c29 -8 40
-50 19 -72 -15 -14 -15 -18 4 -49 15 -24 17 -33 7 -33 -7 0 -21 14 -30 30 -23
40 -48 40 -48 0 0 -16 -4 -30 -10 -30 -6 0 -10 33 -10 80 0 87 4 91 68 74z
m332 -4 c0 -5 -9 -10 -20 -10 -18 0 -20 -7 -20 -70 0 -56 -3 -70 -15 -70 -12
0 -15 14 -15 70 0 63 -2 70 -20 70 -11 0 -20 5 -20 10 0 6 25 10 55 10 30 0
55 -4 55 -10z m-2334 -124 c59 -59 36 -108 -33 -71 -43 23 -70 70 -52 91 19
23 49 16 85 -20z m174 7 c22 -28 20 -70 -3 -85 -51 -32 -127 58 -85 100 21 21
65 14 88 -15z m-310 -23 c75 -75 32 -101 -45 -27 -51 49 -56 67 -20 67 16 0
40 -15 65 -40z m-81 -20 c30 -27 52 -53 48 -56 -8 -8 -94 57 -114 86 -27 38
10 22 66 -30z m259 -107 c37 -28 42 -48 10 -38 -30 9 -88 53 -88 66 0 15 40 1
78 -28z m87 12 c27 -26 33 -55 12 -55 -14 0 -58 28 -69 44 -28 40 20 49 57 11z
m115 10 c10 -12 10 -21 2 -40 -16 -34 -52 -31 -64 5 -14 39 36 67 62 35z
m-285 -22 c55 -27 77 -51 30 -33 -37 14 -105 50 -105 55 0 10 22 4 75 -22z
m405 17 c0 -19 -48 -60 -70 -60 -24 0 -26 17 -4 48 16 23 74 32 74 12z m98 -2
c-6 -17 -100 -68 -112 -61 -10 7 36 49 69 63 29 13 47 12 43 -2z"/>
<path d="M3910 2410 c0 -29 2 -30 45 -30 43 0 45 1 45 30 0 29 -2 30 -45 30
-43 0 -45 -1 -45 -30z"/>
<path d="M3910 2290 c0 -29 2 -30 45 -30 43 0 45 1 45 30 0 29 -2 30 -45 30
-43 0 -45 -1 -45 -30z"/>
<path d="M2780 2040 c0 -23 5 -30 19 -30 28 0 47 26 31 45 -7 8 -21 15 -31 15
-14 0 -19 -7 -19 -30z"/>
<path d="M3380 2040 c0 -23 5 -30 19 -30 28 0 47 26 31 45 -7 8 -21 15 -31 15
-14 0 -19 -7 -19 -30z"/>
</g>
</svg>


        
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}